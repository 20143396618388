import axios from 'axios';
export function item(){
    const temp = {
        data: function(){
            return{
                modal: false,
                sended: false,
                error: false,
                success: false,
            }
        },
        template:
        `
        <li class="listable__item" :id="datalist.day" :class="{'disable': !datalist.bool}">
            <i class="badge" v-if="datalist.badge == 1">済</i>
            <dl class="listable__item__date">
                <dt>{{ datalist.day }}</dt>
                <dd>{{ datalist.title }}</dd>
            </dl>
            <p class="add" @click="opener()">Add Note</p>

            <div v-if="modal" class="overlay" v-cloak>
                <div class="overlay__box">
                    <i class="overlay__box__close" @click="close()"></i>
                    <h3>Add Note</h3>
                    <dl>
                        <dt>Title</dt>
                        <dd><input type="text" v-model="datalist.title"></dd>
                    </dl>
                    <dl>
                        <dt>Note</dt>
                        <dd><textarea v-model="datalist.note"></textarea></dd>
                    </dl>
                    <dl>
                        <dt>Complete</dt>
                        <dd class="clum">
                            <label><input type="radio" name="badges" value="1" v-model="datalist.badge" />true</label>
                            <label><input type="radio" name="badges" value="0" v-model="datalist.badge" />false</label>
                        </dd>
                    </dl>
                    <input :class="{'disable': !sended}" type="button" value="Submit" @click="send()">
                    <p class="alert" v-if="success">Success!</p>
                    <p class="alert" v-if="error">Error!</p>
                    <div class="loading" v-if="sended"></div>
                </div>
            </div>
        </li>
        `,
        props: {
            datalist: Object
        },
        methods: {
            opener: function(){
                this.modal = true;
            },
            close: function(){
                this.modal = false;
            },
            send: function(){
                this.sended = true;
                this.success = false;
                this.error = false;
                const url = '/include/ajax/add.php';
                let $_this = this;
                setTimeout(function(){
                    axios.post(url , $_this.datalist)
                    .then(response => {
                    	if(response.data != 0){
                            $_this.success = true;
                            $_this.datalist.bool = 1;
                            $_this.datalist.id = response.data;
                        }else{
                            $_this.error = true;
                        }
                        $_this.sended = false;
                    }).catch(error => {
                    	$_this.error = true;
                        $_this.sended = false;
                    });
                },400);
            }
        },
        mounted: function(){
            this.datalist.note = this.datalist.note.replace(/<br>/g, "\n");
        }
    }
    return temp;
};
