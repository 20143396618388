import Vue from 'vue/dist/vue.min';
import {item} from './components/item';

export function vm(){
    const v = new Vue({
        el: '#app',
        components: {
            item: item()
        }
    });
};
